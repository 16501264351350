import React, { useEffect, useRef, useState } from "react"
import fetch from "cross-fetch"
import { useAuth0 } from "@auth0/auth0-react"
import QueryString from "query-string"
import useScript from "../../../hooks/useScript"
import PageRoot from "../../../components/PageRoot"
import ContainerWithPadding from "../../../components/ContainerWithPadding"
import { Button, Spin } from "antd"
import { uiHandleError } from "../../../utils"
import { useTranslation } from "react-i18next"
import { navigate } from "gatsby"

const KlarnaCheckout = ({ location }) => {
  const [clientToken, setClientToken] = useState(null)
  const [purchase, setPurchase] = useState(null)
  const [klarnaClient, setKlarnaClient] = useState(null)
  const { getAccessTokenSilently } = useAuth0()
  const params = QueryString.parse(location.search)
  const { id, coupon } = params
  const [authorizationToken, setAuthorizationToken] = useState(null)
  const initialized = useRef(false)

  const { t } = useTranslation()

  const [loading, setLoading] = useState(true)

  const createKlarnaOrder = async () => {
    try {
      const authToken = await getAccessTokenSilently()
      const response = await fetch("/.netlify/functions/klarna-create-order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ purchaseId: id, couponCode: coupon || "" }),
      })
      //console.log(response)
      if (response.status === 200) {
        const res = await response.json()
        //console.log(res)
        const { finalPrice, item_id, description, client_token, user } = res
        setClientToken(client_token)
        setPurchase({ finalPrice, item_id, description, user })
      } else {
        uiHandleError({ message: t(`error:cannotObtainKlarnaOrderToken`) })
      }
    } catch (error) {
      uiHandleError({ error })
    }
    setLoading(false)
  }

  const authorizeKlarna = () => {
    setLoading(true)
    klarnaClient.Payments.authorize(
      {
        payment_method_category: "klarna",
      },
      {
        purchase_country: process.env.GATSBY_COUNTRY,
        purchase_currency: process.env.GATSBY_CURRENCY,
        locale: (process.env.GATSBY_LANG || "it_IT").replace("_", "-"),
        order_amount: purchase.finalPrice * 100,
        order_tax_amount: 0,
        order_lines: [
          {
            type: "digital",
            reference: purchase.item_id,
            name: purchase.description,
            quantity: 1,
            unit_price: purchase.finalPrice * 100,
            tax_rate: 0,
            total_amount: purchase.finalPrice * 100,
            total_discount_amount: 0,
            total_tax_amount: 0,
          },
        ],
      },
      function (res) {
        setLoading(false)
        const { approved, show_form, error, authorization_token } = res

        if (!approved && !show_form) {
          uiHandleError({ message: t("error:klarnaOrderNotApproved") })
          return
        }

        if (error) {
          return handleKlarnaError(error)
        }

        setAuthorizationToken(authorization_token)
      }
    )
  }

  const handleKlarnaError = klarnaError => {
    uiHandleError({
      message:
        t("error:klarnaOrderInvalidFields") +
        `: ${klarnaError.invalid_fields.join(",")}`,
    })
  }

  const authorizeKlarnaOrder = async () => {
    setLoading(true)
    try {
      const authToken = await getAccessTokenSilently()

      const response = await fetch(
        "/.netlify/functions/klarna-authorize-order",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            purchaseId: id,
            authorizationToken,
          }),
        }
      )

      const data = await response.json()
      navigate(
        `/checkout/klarna/success?state=${data.fraud_status}&purchaseId=${id}`
      )
    } catch (e) {
      uiHandleError({ error: e })
    }
    setLoading(false)
  }

  useScript({
    url: "https://x.klarnacdn.net/kp/lib/v1/api.js",
    id: "klarna-payments-script",
    async: true,
    onLoad: () => {
      if (window.Klarna) {
        setKlarnaClient(window.Klarna)
      } else {
        uiHandleError({ message: t("error:cannotLoadKlarnaRefreshPage") })
      }
    },
  })

  useEffect(() => {
    if (initialized.current) {
      return
    }

    if (klarnaClient && clientToken && purchase) {
      setLoading(true)
      initialized.current = true

      klarnaClient.Payments.init({ client_token: clientToken })
      klarnaClient.Payments.load(
        {
          container: "#klarna-payments-container",
          payment_method_category: "klarna",
        },
        function (res) {
          const { show_form, error } = res
          // if (!show_form) {
          //   setLoading(false)
          //   uiHandleError({
          //     message: t("error:klarnaNotAvailableTryAnotherPaymentMethod"),
          //   })
          //   return
          // }

          if (error) {
            setLoading(false)
            handleKlarnaError(error)
            return
          }

          authorizeKlarna()
        }
      )
    }
  }, [klarnaClient, clientToken, purchase])

  useEffect(() => {
    createKlarnaOrder()
  }, [])

  return (
    <PageRoot isDark={false}>
      <ContainerWithPadding align={"center"} size={"large"}>
        <Spin spinning={loading}>
          <div id="klarna-payments-container"></div>
          <Button
            className="yellow"
            type="primary"
            onClick={authorizeKlarnaOrder}
            loading={loading}
            disabled={!authorizationToken}
          >
            {t("button:klarnaFinalizePurchase")}
          </Button>
        </Spin>
      </ContainerWithPadding>
    </PageRoot>
  )
}
export default KlarnaCheckout
